<template>
  <el-dialog v-model="dialogVisible" title="新增角色" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item" prop="role_name">
        <template #label>
          <span class="label">角色名稱</span>
        </template>
        <el-input v-model="ruleForm.role_name" placeholder="請輸入角色名稱" style="width: 100%" />
      </el-form-item>

      <el-form-item
        class="form-item"
        style="grid-area: 2/1/3/3"
        prop="permission_array_string"
        :validate-status="showError"
        :error="errorMsg"
      >
        <template #label>
          <span class="label">授權功能</span>
        </template>
        <el-cascader
          :show-all-levels="false"
          v-model="ruleForm.permission_array_string"
          :options="options"
          placeholder="請選擇授權功能"
          style="width: 100%"
          clearable
          collapse-tags
          collapse-tags-tooltip
          ref="optionsTree"
          :props="{ emitPath: false, multiple: true, label: 'title', value: 'id' }"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleSave(ruleFormRef)" :disabled="!checkSubmit()" style="margin-left: 25px">
          新增
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref, reactive } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'account-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //取得授權功能列表
    const doGetPermissionTree = useActions('permissionManagement', ['doGetPermissionTree']);
    doGetPermissionTree();
    const getPermissionTree = useGetters('permissionManagement', ['getPermissionTree']);
    const options = ref([]);
    options.value.push(
      { title: '總公司權限', children: getPermissionTree.value.headquarters_permission_tree, id: '001' },
      { title: '分公司權限', children: getPermissionTree.value.permission_tree, id: '002' }
    );
    if (dialogVisible) {
      ruleForm.value = {
        role_id: null,
        role_name: '',
        user_array: [],
        permission_array_string: [],
        permission_array: [],
      };
    }

    //驗證與檢查-----------------------------------------------------------------
    const rules = reactive({
      role_name: [{ required: true, message: '請輸入角色名稱', trigger: 'blur' }],
    });

    const showError = ref('');
    const errorMsg = ref('');
    const validatePermissionArray = () => {
      if (ruleForm.value.permission_array_string.length > 0) {
        showError.value = '';
        errorMsg.value = '';
        return true;
      } else {
        showError.value = 'error';
        errorMsg.value = '必填';
        return false;
      }
      //驗證總公司權限及分公司權限皆為必填
      // const node = optionsTree.value.getCheckedNodes();
      // const data = node
      //   .map((x) => x?.parent)
      //   .map((s) => s?.parent?.data)
      //   .map((t) => t?.id ?? '');

      // if (data.includes('001') || data.includes('002')) {
      //   showError.value = '';
      //   errorMsg.value = '';
      //   return true;
      // } else {
      //   showError.value = 'error';
      //   errorMsg.value = '總公司權限及分公司權限需各至少選一';
      //   return false;
      // }
    };

    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getPermissionTree.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getPermissionTree.value));
      }
      return isValid;
    };

    //新增
    const optionsTree = ref(null);
    const router = useRouter();
    const doSavePermission = useActions('permissionManagement', ['doSavePermission']);
    const doGetPermissionList = useActions('permissionManagement', ['doGetPermissionList']);
    const handleSave = async (form) => {
      try {
        const roleName = await form.validate();
        if (!roleName || !validatePermissionArray()) return;
        ruleForm.value.permission_array_string.forEach((item) => ruleForm.value.permission_array.push({ id: item }));
        ruleForm.value.headquarters_permission_array = [];
        await doSavePermission(ruleForm.value);
        await doGetPermissionList({ start: 0, length: 1, type: 'init' });
        router.push('/account/permission?page=1');
        close();
      } catch (e) {
        e;
      }
    };

    //
    return {
      doGetPermissionTree,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      rules,
      options,
      checkSubmit,
      handleSave,
      optionsTree,
      showError,
      errorMsg,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
